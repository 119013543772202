@use "../../../styles/variables.module" as *;

.dropdown {
  position: relative;

  > div:first-of-type {
    position: relative;

    input {
      width: 90%;
      border: none;
      background-color: white;
      color: black;
    }
  }

  > div:nth-of-type(2) {
    position: absolute;
    z-index: 1;
    width: 100%;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      max-height: 400px;
      overflow: auto;
      background: white;

      li:hover {
        cursor: pointer;
        background-color: $clever-lgray;
      }
    }
  }
}

.dropdownButton {
  width: 10%;
  height: 100%;
  right: 0;
  top: 0;
  position: absolute;
  cursor: pointer;
  color: black;
  border: none;
  text-align: center;
  vertical-align: middle;

  &Disabled {
    cursor: default;
    opacity: .7;
    pointer-events: none;
    color: gray;
  }
}
